<template>
  <v-container fluid>
    <v-row>
      <v-col cols="2">
        <AdminMenu></AdminMenu>
      </v-col>
      <v-col cols="10">
        <v-container grid-list-lg py-0>
          <transition name="fade-transition" mode="out-in">
            <router-view></router-view>
          </transition>
        </v-container>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import AdminMenu from "../components/AdminMenu.vue";
export default {
  name: "AdministrativeActivities",
  components: { AdminMenu }
};
</script>
