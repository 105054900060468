<template>
  <div>
    <v-card
      v-for="item in items"
      :key="item.to.name"
      :to="item.to"
      hover
      class="mb-4 py-6"
      :class="active(item.to)"
    >
      <v-layout column text-center>
        <v-flex class="pa-4">
          <fa-icon :icon="item.icon" size="2x"></fa-icon>
        </v-flex>
        <v-flex class="text-uppercase">{{ item.text }}</v-flex>
      </v-layout>
    </v-card>
  </div>
</template>
<script>
export default {
  name: "AdminMenu",
  data() {
    return {
      drawer: true,
      items: [
        {
          icon: "user",
          text: this.$t("global.users"),
          to: { name: "users" }
        },
        {
          icon: "shield-alt",
          text: this.$t("global.roles"),
          to: { name: "roles" }
        },
        {
          icon: "database",
          text: this.$t("global.logs"),
          to: { name: "backup" }
        }
      ]
    };
  },
  methods: {
    active(url) {
      return { active: this.$route.path.indexOf(url.name) > 0 };
    }
  }
};
</script>
<style scoped lang="scss">
.v-card {
  color: var(--v-primary-base);
  background: var(--v-secondary-base);
  &:hover {
    color: white;
    background-color: #1e88e5; /*darken($color: $primary, $amount: 25);*/
  }
  &.active {
    color: white;
    background-color: var(--v-primary-base);
  }
}
</style>
